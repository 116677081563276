<template>
    <div class="studentDialog">
        <el-dialog :title="myTitle" width="40%"  :visible.sync="dialogVisible" :close-on-click-modal="false">
            <el-form :model="studentForm" ref="studentDialogForm" :rules="studentRules">
                <el-form-item label="学号"  prop="myNo" :label-width="formLabelWidth">
                    <el-input v-model="studentForm.myNo" autocomplete="off" @input="myNoChange"></el-input>
                </el-form-item>
                <el-form-item label="用户名"  prop="name"  :label-width="formLabelWidth">
                    <el-input v-model="studentForm.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="密码"  prop="pass" :label-width="formLabelWidth" v-if="studentForm.id == null">
                    <el-input v-model="studentForm.pass" type="password" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="姓名"  prop="nickName" :label-width="formLabelWidth">
                    <el-input v-model="studentForm.nickName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="联系方式"  prop="phoneNo" :label-width="formLabelWidth">
                    <el-input v-model="studentForm.phoneNo" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="行政班"  prop="hardClassId" :label-width="formLabelWidth">
                    <el-select v-model="studentForm.hardClassId" filterable placeholder="请选择">
                        <el-option v-for="item in hardClassList" :key="item.id" :label="item.name+'('+item.year+')'" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="备注"  prop="remark" :label-width="formLabelWidth">
                    <el-input v-model="studentForm.remark" type="textarea"></el-input>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" :loading="loading" @click="submitForm">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import * as simpleApi from "request/simple";
    import XEUtils from 'xe-utils'
    import * as mUtils from '@/utils/mUtils'

    export default {
        name: 'studentDialog',
        data(){
            return {
                formLabelWidth: '120px', myTitle: null,
                dialogVisible:false, loading: false,
                hardClassList: [],
                studentForm:{ id: null, licenceId: null,  myNo: null, name: null, nick_name: null, pass: null, phoneNo: null, hardClassId: null, remark: null,  },
                studentRules: {
                    name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
                    myNo: [{ required: true, message: '请输入学号', trigger: 'blur' }],
                    hardClassId: [{ required: true, message: '请选择行政班', trigger: 'blur' }],
                    
                },
            }
        },
        components:{
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            }),
        },
      	mounted() {
            this.loadHardClassIdList();
	    },
        props: {
            student: {
                type: Object
            }
        },
        watch: {
            student: {
                deep: true, immediate:true,
                handler(n, o){
                    console.log(n)
                    let licencePre = this.userInfo.licenceKey;
                    if(n){
                        this.studentForm = { id: n.id, licenceId: n.licence_id, myNo: n.my_no, name: n.name, nickName: n.nick_name, hardClassId: n.hardClass_id,
                            phoneNo: n.phone_no, remark: n.remark };
                        if(n.my_no == null) this.studentForm.myNo = (new Date()).getMilliseconds();
                        this.studentForm.pass = '123456';
                        if(n.id == null) this.myTitle = '新建学生'; else this.myTitle = '编辑学生';
                    }else{
                        this.studentForm = { id: null, licenceId: null, myNo: null, name: null, nick_name: null, pass: null,phoneNo: null, hardClassId: null, remark: null, };
                    }
                }
            },
        },
        methods: {
            showDialog(){
                this.dialogVisible = true;
            },
            hideDialog(){
                this.dialogVisible = false;
            },
            cancel(){
                this.dialogVisible = false;
                this.$emit('cancel');
            },
            myNoChange(v){
                this.studentForm.name =  this.userInfo.licenceKey + v;
            },
            submitForm(){
                console.log(this.studentForm);
                let that = this;
                this.$refs.studentDialogForm.validate((valid) => {
                    if(valid){
                        this.loading = true;
                        this.studentForm.licenceId = this.userInfo.licenceId;
                        if(this.studentForm.id == null){
                            this.studentForm.name = this.userInfo.licenceKey + this.studentForm.myNo;
                        }
                        let param = { controllerName: 'sclStudent', methodName: '/saveOrUpdate', param: this.studentForm};

                        simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                            console.log('student', code);
                            if (code == 0) {
                                this.dialogVisible = false;
                                that.$emit('ok');
                            }else if(code == -3){
                                this.$message({message: '该学生已在系统中。。', type: 'error', duration: 5000});
                            }
                            this.loading = false;
                        }).catch((error) => {
                            this.loading = false;
                        });
                    }
                });
            },
            loadHardClassIdList(){

                let search = { licenceId: this.userInfo.licenceId};
                let param = { controllerName: 'sclHardClass', methodName: '/list', pageNo: 0, pageSize: 500, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        this.hardClassList = data.result;
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },

        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .studentDialog{

    }


</style>


